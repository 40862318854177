





















import CommonError from 'common-modules/src/components/CommonError.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import { Course } from 'common-modules/src/store/interface/Course';
import { ExtendedUnit } from '@/store/interface/Unit';
import BackBar from '@/components/BackBar.vue';
import JwlUnit from '@/components/JwlUnit.vue';

@Component({
  components: {
    JwlUnit,
    CommonError,
    BackBar,
    CommonIcon,
  },
})
export default class JwlUnitPreview extends Vue {
  unit: ExtendedUnit | null = null;
  course: Course | null = null;
  error = null;
  syncStatus = 1;

  @Watch('$route')
  fetchData (): void {
    this.syncStatus = 1;
    this.unit = null;
    this.error = null;
    this.$store.dispatch('getData', `courses/unit/${this.$route.params.unit}`)
      .then((data) => {
        this.unit = data.unit;
        this.course = data.course;
        this.syncStatus = 2;
      })
      .catch((e) => {
        this.error = e;
        this.syncStatus = -1;
      });
  }

  get unitColor (): Record<string, boolean> {
    return {
      'jwl-unit-preview--bachelor': this.course?.type === 'bachelor',
      'jwl-unit-preview--professional': this.course?.type === 'professional',
      'jwl-unit-preview--teacher-training': this.course?.type === 'teacher-training',
      'jwl-unit-preview--academy': this.course?.type === 'academy',
    };
  }

  created (): void {
    this.fetchData();
  }
}
